.header {
  z-index: 10;
  height: 4em;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: fixed;
  top: 0;
  width: 100%;
  max-width: 100vw;
}
.logoimage {
  height: 3.75em;
  cursor: pointer;
  position: absolute;
  left: 5em;
}
.navlist {
  display: flex;
  font-size: 0.9em;
  color: rgb(115, 115, 115);
}
.navlist > li {
  margin: 1em 1em;
  cursor: pointer;
}
.navlist > span {
  margin: 1em 0.7em;
  cursor: pointer;
  color: #1a2e35;
}

.button {
  margin-left: 1em;
  background-color: #ade5ab;
  border: none;
  color: black;
  font-size: 16px;
  padding: 0.9em 2.27em;
  border-radius: 0.7em;
  cursor: pointer;
  position: absolute;
  right: 5em;
}
.contactButton:hover {
  background-color: black;
}
@media screen and (max-width: 62em) {
  .navlist {
    display: none;
  }
  .button {
    right: 1em;
  }
  .logoimage {
    left: 1em;
  }
  .button {
    font-size: 12px;
  }
}
