.ContentPage {
  margin-top: 2em !important;
  margin-bottom: 10em !important;
  padding: 1em;
}
.HeroSection {
  margin: 4em 0em;
}
.Header h2 {
  color: #67794f;
  font-size: 2.25em;
  font-weight: 500;
  margin-top: 0em;
}
.InfoContainer {
  margin-bottom: 8em;
}
.Header small {
  font-size: 0.6em;
}
.Header h3 {
  font-size: 2.25em;
  font-weight: 600;
  width: 15em;
}
.InfoContainer p {
  width: 23em;
  font-size: 1.1em;
}
.InfoContainer img {
  width: 32em;
}
.hero2 {
  margin-bottom: 1em;
  margin-left: 8em;
}
.hero2 img {
  width: 25em;
  margin-left: 2em;
}
.mobileImage {
  display: none;
}
.imageContainer{
  padding: 2em 0;
}

@media screen and (max-width: 95em) and (min-width: 80em) {
  .ContentPage {
    max-width: 90% !important;
  }
  .Header h2 {
    font-size: 2em;

  }
  .Header h3 {
    width: 12em;
  }
  .InfoContainer img {
    width: 27em;
  }
  .hero2 {
    margin: 0 2em 2em 2em;
  }
  .hero2 img {
    width: 18em;
  }
}
@media screen and (max-width: 79.9em) and (min-width: 62em) {
  .ContentPage {
    max-width: 90% !important;
  }
  .Header h2 {
    font-size: 2em;

  }
  .Header h3 {
    width: 12em;
  }
  .InfoContainer img {
    width: 22em;
    margin: 0 2em;
  }
  .hero2 {
    margin: 0 2em 2em 2em;
  }
  .hero2 img {
    width: 18em;
  }
  .imageContainer{
    padding: 0;
  }
}
@media screen and (max-width: 45em) {
  .InfoContainer img {
    max-width: 22em;
    margin-left: 0em;
  }
  .imageContainer{
    text-align: center;
  }
  .mobileImage {
    display: block;
  }
  .desktopImage {
    display: none;
  }
  .InfoContainer {
    width: 90vw;
    margin-bottom: 0em;
  }
  .InfoContainer h3 {
    font-size: 2em;
    width: auto;
  }
  .InfoContainer h2 {
    font-size: 1.75em;
    width: auto;
  }
  .InfoContainer p {
    font-size: 1em;
    width: auto;
  }
  .hero2 {
    margin: 0em;
  }
}
@media screen and (max-width: 37.48em) {
  .ContentPage {
    margin-top: 0em !important;
  }
}
@media screen and (max-width: 35em) {
  .InfoContainer img {
    max-width: 18em;
    text-align: center;
  }
 
}
