.TemplateFooter {
  background-color: #1a2e35;
}

.FooterPanel {
  width: 100%;
  margin: 0 auto;
  margin-top: 1.5em;
  background-color: #1a2e35 !important;
  color: white;
}

.FooterContent {
  padding: 8em 0em;
  width: 28em;
  margin: 0 auto;
}

.FooterTitle {
  font-size: 3.5em;
  margin: 1em 0em;
}

.FooterSubtitle {
  margin: 0 0 1em;
  font-size: 1.125em;
  color: #ade5ab;
}

.BtnContainer {
  margin: 1.618em 0;
}

.BtnContainer button {
  background-color: #dceacb;
  border: none;
  color: #1a2e35;
  cursor: pointer;
  border-radius: 2em;
  padding: 1em 1.75em;
  margin: 0 0.25em;
  text-decoration: none;
  display: inline-block;
  font-size: 1.1em;
}

.FooterNavLinks {
  display: flex;
  justify-content: space-between;
  padding: 0em 0 5em;
  width: 80%;
  margin: 0 auto;
  padding-top: 5em;
}

.FooterNavColumn ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.FooterNavColumn li {
  margin-bottom: 0.618em;
}

.FooterNavLinks a {
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 0.5em;
}
.lineBottom {
  border-bottom: 1px solid rgba(255, 255, 255, 0.189);
}
.FooterBranding {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em;
  font-size: 0.8rem;
  position: relative;
}
.FooterBranding span {
  color: #ade5ab;
}
.FooterBranding a {
  text-decoration: none;
  font-family: 'Poppins', sans-serif !important;
  color: white;
}

.FooterLogoContainer {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.FooterIconWrapper {
  width: 1.618em;
  height: 1.618em;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.FooterIcon {
  width: 1em;
  height: 1em;
}

.socialIcons {
  display: flex;
  gap: 0.5em;
}
@media screen and (max-width: 62em) {
  .FooterContent {
    text-align: center;
    width: auto;
    padding-top: 5em;
    padding-bottom: 0em;
  }
  .FooterTitle {
    font-size: 2.25em;
    text-align: center;
    font-weight: bold;
  }
  .BtnContainer button {
    padding: 0.5em 1.75em;
  }
  .FooterLogo{
    position: relative;
    top: 4em !important  ;
  }
  .lineBottom {
    border-bottom: none;
    display: block;
    text-align: center;
  }
  .FooterBranding{
    display: block;
    margin-top: 5em;
    text-align: center;
  }
}

/* @media screen and (max-width: 32em) {
  .FooterBranding {
    padding: 2em;
    display: block;
  }
  .BtnContainer a {
    padding: 0.618em 1em;
    font-size: 1.1em;
  }
  .FooterLogoContainer {
    left: 2.5em;
    top: 10%;
  }
} */
